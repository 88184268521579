import logo from "./logo.svg";
import "./App.css";
import React, { useRef } from "react";
import { SketchPicker } from "react-color";
import Versace from "./Versace";
import Kitchen from "./Kitchen";
import { useEffect } from "react";
import { useState } from "react";

// if (process.env.NODE_ENV !== "development") {
// console.log = () => {};
// }

function App() {
  const [loaded, setLoadedPage] = useState("loading");
  useEffect(() => {
    if (window.location.href.endsWith("/versace")) {
      setLoadedPage("versace");
    } else if (window.location.href.endsWith("/kitchen")) {
      setLoadedPage("kitchen");
    } else {
      setLoadedPage("not_found");
    }
  }, []);
  switch (loaded) {
    case "versace":
      return <Versace />;
    case "kitchen":
      return <Kitchen />;
    case "not_found":
      return (
        <center>
          <h1>404 not found</h1>
        </center>
      );
    case "loading":
      return (
        <center>
          <h1>Loading...</h1>
        </center>
      );
  }
}

export default App;
