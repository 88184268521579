import logo from "./logo.svg";
import "./App.css";
import React, { useRef } from "react";
import { SketchPicker } from "react-color";

// if (process.env.NODE_ENV !== "development") {
// console.log = () => {};
// }

function Kitchen() {
  // console.log(window, window.SketchfabAPIClient, window.Sketchfab);

  const ref = useRef();
  const api = useRef();
  const setColorRef = useRef();

  // import("https://static.sketchfab.com/api/sketchfab-viewer-1.9.0.js").then(a =>console.log)

  //   <script type="text/javascript">
  // function onSketchfabUtilityReady() {
  //    //your code here

  // }
  // var sketchfabAPIUtility = new SketchfabAPIUtility('e20b521b20964d1893d09a7cc7c57889', document.getElementById('api-frame'), onSketchfabUtilityReady);

  function findNameInSceneGraph(name, graph) {
    if (!graph) return null;
    if (graph.name == name) return graph;
    if (!graph.children || !graph.children.length) return null;
    for (var c = 0; c < graph.children.length; c++) {
      let res = findNameInSceneGraph(name, graph.children[c]);
      if (res) return res;
    }
    return null;
  }

  function init() {
    const Sketchfab = window.Sketchfab;

    var iframe = ref.current; // document.getElementById("api-frame");
    var uid = "1392bf13cf864f31b7523389ea04e31f"; //"7w7pAfrCfjovwykkEeRFLGw5SXS";

    // By default, the latest version of the viewer API will be used.
    var client = new Sketchfab(iframe);

    // Alternatively, you can request a specific version.
    // var client = new Sketchfab( '1.9.0', iframe );

    client.init(uid, {
      ui_controls: 0,
      ui_infos: 0,
      success: function onSuccess(api) {
        api.current = api;

        // console.log("SETUP 💛");

        // api.getNodeMap((err, nodes) => {
        //   console.log(88, err, nodes);
        // });

        // api.getSceneGraph((err, graph) => {
        //   console.log(99, err, graph);
        // });

        // const id = "fornello1"; //0

        // document.getElementById("hide").addEventListener("click", function () {
        //   console.log(22);
        //   api.hide(id);
        // });

        // document.getElementById("show").addEventListener("click", function () {
        //   console.log(223);
        //   api.show(id);
        // });

        api.start(function () {
          api.addEventListener("viewerready", function () {
            api.getSceneGraph(function (err, result) {
              // window.console.log(55, err, result);

              var fornello1 = findNameInSceneGraph("fornello_1", result);
              var fornello2 = findNameInSceneGraph("fornello_2", result);
              // window.console.log(22, fornello1, fornello2);

              api.hide(fornello1.instanceID);

              document.getElementById("induzione").addEventListener("click", function () {
                api.hide(fornello1.instanceID);
                api.show(fornello2.instanceID);
              });

              document.getElementById("gas").addEventListener("click", function () {
                api.hide(fornello2.instanceID);
                api.show(fornello1.instanceID);
              });

              //! others
              var Kitchen = findNameInSceneGraph("Kitchen", result);
              var Mesh = findNameInSceneGraph("Mesh", result);
              var cappa = findNameInSceneGraph("cappa", result);

              // document.getElementById("t1").addEventListener("click", function () {
              //   api.hide(Mesh.instanceID);
              //   api.show(cappa.instanceID);
              // });

              // document.getElementById("t2").addEventListener("click", function () {
              //   api.show(Mesh.instanceID);
              //   api.hide(cappa.instanceID);
              // });
            });

            // console.log(11);
            api.getAnnotationList(function (err, annotations) {
              if (err) {
                console.log("error getting annotations");
                return;
              }

              // console.log(22, annotations);

              let pianoIndex = annotations.findIndex((item) => item.name === "Piano Cottura");
              let cappaIndex = annotations.findIndex((item) => item.name === "Cappa");

              if (pianoIndex >= 0) {
                document.getElementById("induzione").addEventListener("click", function () {
                  api.gotoAnnotation(pianoIndex);
                });

                document.getElementById("gas").addEventListener("click", function () {
                  api.gotoAnnotation(pianoIndex);
                });
              }

              if (cappaIndex >= 0) {
                api.addEventListener("annotationSelect", function (index) {
                  // window.console.log("Selected annotation", index);
                  if (index === cappaIndex) {
                    let player = document.getElementById("video-play");
                    if (player) player.click();
                  }
                });
              }
            });

            api.getMaterialList(function (err, materials) {
              // Turn off the diffuse on the first material
              var materialToUpdate = materials[0];

              var materiale = materials.find((m) => m.name === "RS_Mobiletti");
              // var materiale = materials.find((m) => m.name === "RS_Metallo_nero");

              // window.console.log(711, materials);
              // window.console.log(722, materialToUpdate);
              // window.console.log(733, materiale);

              let onChannels = Object.keys(materiale.channels).find((k) => materiale.channels[k].enabled);
              // window.console.log(744, onChannels);

              // document.getElementById("b3").addEventListener("click", function () {
              //   // Apply the change
              //   materialToUpdate.channels.DiffuseColor.enable = false;
              //   api.setMaterial(materialToUpdate, function () {
              //     window.console.log("Material updated");
              //   });
              // });

              setColorRef.current = (colors) => {
                materiale.channels.DiffusePBR.color = colors;
                api.setMaterial(materiale, function () {
                  // window.console.log("xyz");
                });
              };

              function applyColor(rgb) {
                materiale.channels.DiffusePBR.color = rgb;
                api.setMaterial(materiale, function () {
                  // window.console.log("Material updated", rgb);
                });
              }

              [
                [0.3, 0, 0], //red
                [0.7, 0.7, 0.2], //yellow
                [1, 0.4, 0], //orange
                [0, 0, 0.2], //blue
                [1, 1, 1], //white
                [0, 0, 0], //black
              ].forEach((color, index) => {
                let btn = document.getElementById("b" + (index + 1));
                if (btn) {
                  btn.addEventListener("click", function () {
                    applyColor(color);
                  });
                }
              });

              /*
              document.getElementById("test").addEventListener("click", function () {
                // Apply the change
                // materiale.channels.SpecularColor.enable = false;
                // materiale.channels.DiffuseColor.enable = false;
                // materiale.channels.SpecularPBR.enable = false;
                // materiale.channels.SpecularHardness.enable = false;

                // materiale.channels.ClearCoat.enable = false;
                // materiale.channels.Matcap.enable = false;
                // materiale.channels.EmitColor.enable = false;

                // materiale.channels.BumpMap.enable = false;
                // materiale.channels.ClearCoatNormalMap.enable = false;
                // materiale.channels.ClearCoatRoughness.enable = false;

                // materiale.channels.DiffuseIntensity.enable = false;
                // materiale.channels.DiffusePBR.enable = false;
                // materiale.channels.RoughnessPBR.enable = false;//!<---

                //!----
                // materiale.channels.SpecularColor.enable = false;
                // materiale.channels.DiffuseColor.enable = false;
                // materiale.channels.SpecularPBR.enable = false;
                // materiale.channels.SpecularHardness.enable = false;
                // materiale.channels.ClearCoat.enable = false;
                // materiale.channels.Matcap.enable = false;
                // materiale.channels.EmitColor.enable = false;
                // materiale.channels.BumpMap.enable = false;
                // materiale.channels.ClearCoatNormalMap.enable = false;
                // materiale.channels.ClearCoatRoughness.enable = false;
                // materiale.channels.DiffuseIntensity.enable = false;
                // materiale.channels.DiffusePBR.enable = false;
                // materiale.channels.RoughnessPBR.enable = false;

                //? color change
                //ClearCoat
                //DiffusePBR
                //Matcap
                //SpecularPBR

                // materiale.channels.ClearCoat.color = [1, 0.5, 0];
                materiale.channels.DiffusePBR.color = [1, 0.5, 0];
                // materiale.channels.Matcap.color = [1, 0.5, 0];
                // materiale.channels.SpecularPBR.color = [1, 0.5, 0];

                api.setMaterial(materiale, function () {
                  window.console.log("Material updated");
                });
              });
              */
            });

            //!--

            // api.getNodeMap(function (err, result) {
            //   console.log(44, err, result);
            // });

            // var id = "fornello_1"; //0
            // document.getElementById("hide").addEventListener("click", function () {
            //   api.hide(id);
            // });

            // document.getElementById("show").addEventListener("click", function () {
            //   api.show(id);
            // });
          });
        });

        // api.addEventListener("viewerready", function () {
        //   // API is ready to use
        //   // Insert your code here
        //   console.log("Viewer is ready");
        // });
      },
      error: function onError() {
        // console.log("Viewer error");
      },
    });
  }

  const onCappaClick = () => {
    console.log("🟨 Cappa");
  };

  return (
    <React.Fragment>
      <iframe
        ref={(r) => {
          // if (!ref.current) {
          if (r) ref.current = r;
          init();
          // }
        }}
        src=""
        id="api-frame"
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        // width={window.innerWidth}
        // height={window.innerHeight}
        style={{ width: "100vw", height: "100vh", border: "none" }}
      />

      <div id="video-play" style={{ display: "none" }} onClick={() => onCappaClick()} />

      <div
        // css={{ backgroundColor: "orange" }}
        style={{
          position: "fixed",
          // top: 70,
          bottom: 5,
          left: 5,
        }}
      >
        <h4 children="COLORE" />
        <button id="b1" style={{ background: "red" }} className="btn-ui color" />
        {/* <button id="b2" children="yellow" style={{ background: "yellow" }} /> */}
        {/* <button id="b3" children="orange" style={{ background: "orange" }} /> */}
        {/* <button id="b4" children="blue" style={{ background: "blue" }} /> */}
        <button id="b5" style={{ background: "white", color: "black" }} className="btn-ui color" />
        <button id="b6" style={{ background: "black" }} className="btn-ui color" />

        <div style={{ height: 16 }} />

        <h4 children="PIANO COTTURA" />
        <button id="induzione" children="induzione" className="btn-ui" />
        <button id="gas" children="gas" style={{ marginLeft: 8 }} className="btn-ui" />
      </div>
    </React.Fragment>
  );
}

/*

// Sketchfab Viewer API: Start/Stop the viewer
var version = '1.9.0';
var uid = 'dd958716be0b4786b8700125eec618e5';
var iframe = document.getElementById('api-frame');
var client = new window.Sketchfab(version, iframe);

var error = function error() {
  console.error('Sketchfab API error');
};

var success = function success(api) {
  api.start(function () {
    api.addEventListener('viewerready', function () {
      api.getSceneGraph(function (err, result) {
        if (err) {
          console.log('Error getting nodes');
          return;
        } // get the id from that log


        console.log(result);
      });
      var id = 191;
      document.getElementById('hide').addEventListener('click', function () {
        api.hide(id);
      });
      document.getElementById('show').addEventListener('click', function () {
        api.show(id);
      });
    });
  });
};

client.init(uid, {
  success: success,
  error: error,
  autostart: 1,
  preload: 1
}); //////////////////////////////////
// GUI Code
//////////////////////////////////

function initGui() {
  var controls = document.getElementById('controls');
  var buttonsText = '';
  buttonsText += '<button id="show">Show</button>';
  buttonsText += '<button id="hide">Hide</button>';
  controls.innerHTML = buttonsText;
}

initGui(); //////////////////////////////////
// GUI Code end
//////////////////////////////////
*/

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default Kitchen;
