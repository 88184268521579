import logo from "./logo.svg";
import "./App.css";
import React, { useRef } from "react";
import { SketchPicker } from "react-color";
import { useState } from "react";

// if (process.env.NODE_ENV !== "development") {
// console.log = () => {};
// }

function Versace() {
  // console.log(window, window.SketchfabAPIClient, window.Sketchfab);

  const ref = useRef();
  const api = useRef();
  const setColorRef = useRef();

  const [textures] = useState(() => ({
    purple: {
      // url: "https://i.imgur.com/Ua9ImIr.jpg",
      // url: "/texture/purple.png",
      url: "https://ar.mabiloft.com/texture/purple.png",
      name: "purple",
      uid: null,
    },
    white: {
      url: "https://ar.mabiloft.com/texture/white.png",
      name: "white",
      uid: null,
    },
    green: {
      url: "https://ar.mabiloft.com/texture/green.png",
      name: "green",
      uid: null,
    },
  }));

  // import("https://static.sketchfab.com/api/sketchfab-viewer-1.9.0.js").then(a =>console.log)

  //   <script type="text/javascript">
  // function onSketchfabUtilityReady() {
  //    //your code here

  // }
  // var sketchfabAPIUtility = new SketchfabAPIUtility('e20b521b20964d1893d09a7cc7c57889', document.getElementById('api-frame'), onSketchfabUtilityReady);

  function findNameInSceneGraph(name, graph) {
    if (!graph) return null;
    if (graph.name == name) return graph;
    if (!graph.children || !graph.children.length) return null;
    for (var c = 0; c < graph.children.length; c++) {
      let res = findNameInSceneGraph(name, graph.children[c]);
      if (res) return res;
    }
    return null;
  }

  function init() {
    const Sketchfab = window.Sketchfab;
    var iframe = ref.current;
    // const uid = "88c8d294d01b48059fb7a69b641e15bb"; // silver
    const uid = "c20658f89fad43c186f6751b18538a13"; // white
    // const uid = "743332bc45c7450895ff1da12c0c8450"; // purple

    var client = new Sketchfab(iframe);

    client.init(uid, {
      ui_controls: 0,
      ui_infos: 0,
      preload: 1,
      success: function onSuccess(api) {
        api.current = api;

        api.start(function () {
          api.addEventListener("viewerready", function () {
            if (false)
              api.getSceneGraph(function (err, result) {
                // window.console.log(55, err, result);

                var fornello1 = findNameInSceneGraph("fornello_1", result);
                var fornello2 = findNameInSceneGraph("fornello_2", result);
                // window.console.log(22, fornello1, fornello2);

                api.hide(fornello1.instanceID);

                document.getElementById("induzione").addEventListener("click", function () {
                  api.hide(fornello1.instanceID);
                  api.show(fornello2.instanceID);
                });

                document.getElementById("gas").addEventListener("click", function () {
                  api.hide(fornello2.instanceID);
                  api.show(fornello1.instanceID);
                });

                //! others
                var Kitchen = findNameInSceneGraph("Kitchen", result);
                var Mesh = findNameInSceneGraph("Mesh", result);
                var cappa = findNameInSceneGraph("cappa", result);

                // document.getElementById("t1").addEventListener("click", function () {
                //   api.hide(Mesh.instanceID);
                //   api.show(cappa.instanceID);
                // });

                // document.getElementById("t2").addEventListener("click", function () {
                //   api.show(Mesh.instanceID);
                //   api.hide(cappa.instanceID);
                // });
              });

            // console.log(11);
            if (false)
              api.getAnnotationList(function (err, annotations) {
                if (err) {
                  console.log("error getting annotations");
                  return;
                }

                // console.log(22, annotations);

                let pianoIndex = annotations.findIndex((item) => item.name === "Piano Cottura");
                let cappaIndex = annotations.findIndex((item) => item.name === "Cappa");

                if (pianoIndex >= 0) {
                  document.getElementById("induzione").addEventListener("click", function () {
                    api.gotoAnnotation(pianoIndex);
                  });

                  document.getElementById("gas").addEventListener("click", function () {
                    api.gotoAnnotation(pianoIndex);
                  });
                }

                if (cappaIndex >= 0) {
                  api.addEventListener("annotationSelect", function (index) {
                    // window.console.log("Selected annotation", index);
                    if (index === cappaIndex) {
                      let player = document.getElementById("video-play");
                      if (player) player.click();
                    }
                  });
                }
              });

            // if (false)
            api.getMaterialList(function (err, materials) {
              if (true) {
                var material = materials.find((m) => m.name === "lambert1");
                // console.log(99, material);

                for (const texture in textures) {
                  // if (true) {
                  //   console.log("skip", texture);
                  //   continue;
                  // }
                  api.addTexture(textures[texture].url, (err, uid) => {
                    if (!err) {
                      textures[texture].uid = uid;
                      // window.console.log(`Registered new texture, ${textures[texture].name}, uid: ${uid}`);
                      const elemId = `b_${textures[texture].name}`;
                      const element = document.getElementById(elemId);

                      if (!element) {
                        console.log(`Elemento ${elemId} non trovato`);
                        return;
                      }

                      // const slider = document.getElementById("slider");
                      // if (slider) {
                      //   slider.addEventListener("input", function () {
                      //     var rotation = slider.value;
                      //     console.log(33, rotation);
                      //     api.setUVRotation(material, "AlbedoPBR", rotation);
                      //   });
                      // }

                      element.className = (element.className || "").replace("loading", "");

                      element.addEventListener("click", () => {
                        let materialToUpdate = material;
                        materialToUpdate.channels.AlbedoPBR.texture.uid = textures[texture].uid;
                        materialToUpdate.channels.AlbedoPBR.enable = true;

                        // const fixMaterial = () => {
                        //   api.setUVScale(materialToUpdate, "AlbedoPBR", -1, 1);
                        //   api.setUVRotation(materialToUpdate, "AlbedoPBR", Math.PI);
                        // };

                        // setTimeout(fixMaterial, 1);
                        // setTimeout(fixMaterial, 10);
                        // setTimeout(fixMaterial, 100);
                        // setTimeout(fixMaterial, 500);
                        // setTimeout(fixMaterial, 1000);

                        api.setMaterial(materialToUpdate, () => {
                          // window.console.log("Updated material.");
                        });
                      });
                    } else {
                      console.log("❌", texture, err);
                    }
                  });
                }

                return;
              }

              var materialToUpdate = materials[0];
              var materiale = materials.find((m) => m.name === "RS_Mobiletti");
              let onChannels = Object.keys(materiale.channels).find((k) => materiale.channels[k].enabled);

              setColorRef.current = (colors) => {
                materiale.channels.DiffusePBR.color = colors;
                api.setMaterial(materiale, function () {
                  // window.console.log("xyz");
                });
              };

              function applyColor(rgb) {
                materiale.channels.DiffusePBR.color = rgb;
                api.setMaterial(materiale, function () {
                  // window.console.log("Material updated", rgb);
                });
              }

              [
                [0.3, 0, 0], //red
                [0.7, 0.7, 0.2], //yellow
                [1, 0.4, 0], //orange
                [0, 0, 0.2], //blue
                [1, 1, 1], //white
                [0, 0, 0], //black
              ].forEach((color, index) => {
                let btn = document.getElementById("b" + (index + 1));
                if (btn) {
                  btn.addEventListener("click", function () {
                    applyColor(color);
                  });
                }
              });
            });
          });
        });
      },
      error: function onError() {
        // console.log("Viewer error");
      },
    });
  }

  const onTheClick = () => {
    console.log("🟨 TheClick");
  };

  return (
    <React.Fragment>
      <iframe
        ref={(r) => {
          if (r && !ref.current) {
            ref.current = r;
            init();
          }
        }}
        src=""
        id="api-frame"
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        style={{ width: "100vw", height: "100vh", border: "none" }}
      />

      <div id="video-play" style={{ display: "none" }} onClick={() => onTheClick()} />

      <div
        style={{
          position: "fixed",
          bottom: 5,
          left: 5,
        }}
      >
        <h4 children="COLORE" />
        <button id="b_purple" style={{ background: "purple" }} className="btn-ui square color loading" />
        <button id="b_white" style={{ background: "white", color: "black" }} className="btn-ui square color loading" />
        <button id="b_green" style={{ background: "rgb(49 93 79)" }} className="btn-ui square color loading" />

        <div css={{ position: "absolute" }}>
          {/* <input id="slider" type="range" min="1" max="10" step="0.01" style={{ width: 300 }}></input> */}
          {/* <img src={"/texture/green.png"} /> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Versace;
